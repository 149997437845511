<template>
  <div>
    <div v-if="!errorPage">
      <div>
        <Card class="mt-6 p-5">
          <template v-slot:footer>
            <card-footer
              @sortType="handleSort($event)"
              :show-map-pin="true"
              @searchResult="handleSearch($event)"
            />
          </template>
        </Card>
      </div>
      <div class="pt-5">
        <sTable
          :headers="locationHeader"
          :items="locationData"
          aria-label="show location"
          :loading="loading"
          :pagination-list="metaData"
          @page="handlePage($event)"
          @filter="handleFilter($event)"
          @itemsPerPage="handleItemsPerPage($event)"
          page-sync
          class="w-full mb-4"
          :has-number="false"
        >
          <template v-slot:item="{ item }">
            <span v-if="item.name" class="">{{ item.data.name }}</span>
            <span v-if="item.address" class="">{{ item.data.address }}</span>
            <span v-if="item.country" class="">{{ item.data.country }}</span>
            <span v-if="item.state" class="">{{ item.data.state }}</span>
            <span v-if="item.employeesCount" class="">{{ item.data.employeesCount }}</span>
            <span class="flex" v-if="item.id">
              <Menu left top="-15" class="p-0" margin="30">
                <template v-slot:title>
                  <icon
                    icon-name="more_icon"
                    size="xsm"
                    class="-mt-1"
                    style="padding: 3px 10px; width: 36px; height: 36px"
                  />
                </template>
                <div style="width: 158px" class="py-3 px-2">
                  <div
                    class="flex py-1 px-3 h-8 cursor-pointer"
                    @click="editLocation(item.data)"
                  >
                    <icon
                      icon-name="edit"
                      class-name="text-blueCrayola mr-2"
                      size="xs"
                    />
                    <p class="pt-1" style="font-size: 14px">View and Edit</p>
                  </div>
                  <div
                    v-if="!item.data.isDefaultOffice"
                    class="flex px-3 py-1 more h-8 cursor-pointer"
                    @click="confirmDeleteLocation(item.data)"
                  >
                    <icon
                      icon-name="delete"
                      class-name="text-desire mr-2"
                      size="xs"
                    />
                    <p class="pt-1" style="font-size: 14px">Delete</p>
                  </div>
                </div>
              </Menu>
            </span>
            <span v-else-if="item.stateId">
              {{ handleState(item.data.stateId) }}
            </span>
          </template>
        </sTable>
      </div>
      <add-location
        :open-modal="id ? openEditModal : openModal"
        @close="handleClose"
        :id="id"
      />
      <Modal v-if="deleteDialog">
        <Card class="p-5 flex flex-col max-w-md">
          <div class>
            <p class="font-bold ml-4 my-5 text-center">
              Are you sure you want to delete this Location?
            </p>
            <div class="flex">
              <Button
                class="text-white"
                :background="appearance.buttonColor || defaultBTNColor"
                @click="deleteLocation"
              >
                Yes
              </Button>
              <Button
                background-color="none"
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
                @click="deleteDialog = false"
              >
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      </Modal>
    </div>

    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import CardFooter from "@/components/CardFooter";
import Menu from "@/components/Menu";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import states from "@/helpers/states";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "Location",
  components: {
    Card,
    STable,
    Menu,
    CardFooter,
    ErrorPage,
    Modal,
    Button,
    AddLocation:() => import('./sideActions/addLocation'),
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentTab: "Location",
      states,
      id: "",
      deleteDialog: false,
      metaData: {},
      locationId: "",
      openEditModal: false,
      itemsPerPage: null,
      numberOfPage: null,
      searchWord: null,
      errorPage: false,
      loading: true,
      sortType: null,
      locationData: [],
      locationHeader: [
        { title: "LOCATION NAME", value: "name", width: 18 },
        { title: "ADDRESS", value: "address", width: 30 },
        { title: "COUNTRY", value: "country", width: 18 },
        { title: "STATE", value: "state", width: 15 },
        { title: "EMPLOYEES", value: "employeesCount", width: 14 },
        { title: "", value: "id", image: true },
      ],
    };
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    handleState(stateNo) {
      const result = this.states.find((state) => state.id === stateNo);
      if (result) {
        return result.name;
      }
      return stateNo;
    },
    getName(value, array) {
      if (!value) {
        return "";
      }
      const result = array.find(({ id }) => id === value);
      if (result) {
        return result.name;
      }
      return value;
    },
    queryUpdate() {
      this.loading = true;
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getLocations(`${sort}${pageNumber}${itemPage}${search}`);
    },
    getLocations(query) {
      this.$_getHumanarLocations(query).then((result) => {
        this.locationData = result.data.outlets;
        this.metaData = result.data.meta;
        this.loading = false;
      });
    },
    async editLocation(data) {
      this.id = "";
      try {
        await this.$handlePrivilege("location", "editLocation");
        this.id = data.id;
        this.openEditModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handleClose() {
      this.id = "";
      this.getLocations();
      this.openEditModal = false;
      this.$emit("close");
    },
    handleSort(sortType) {
      this.loading = true;
      const changeSortType = sortType.split(":");
      let result;
      if (changeSortType[0] === "createdAt") {
        result = sortType;
      } else {
        result = `name:${changeSortType[1]}`;
      }
      this.sortType = result;
      this.queryUpdate();
    },
    handleSearch(searchWord) {
      this.loading = true;
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    async confirmDeleteLocation(data) {
      try {
        await this.$handlePrivilege("location", "deleteLocation");
        this.locationId = data.id;
        this.deleteDialog = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async deleteLocation() {
      this.deleteDialog = false;
      this.loading = true;
      this.$_deleteOutlet([this.locationId]).then(() => {
        this.getLocations();
      })
      .catch((err) => {
        this.$toasted.error(err, { duration: 5000 });
        this.getLocations()
        });
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege(
        "location",
        "viewRegister"
      );
      this.getLocations();
    } catch (error) {
      this.errorPage = true;
    }
  },
};
</script>

<style></style>
