<template>
  <div class="w-full h-full pt-5">
    <h1 class="text-xl text-left font-extrabold pl-4">
      {{ currentTab === "Location" ? "Location" : "Tax" }}
    </h1>
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div class="px-3" v-else>
      <card class="p-5 mt-3 ">
        <div class="flex mb-6 mr-3" style="justify-content: flex-end;">
          <Button
            v-if="currentTab === 'Location'"
            class="text-white"
            :background="appearance.buttonColor || defaultBTNColor"
            @click="addLocation"
          >
            New Location
          </Button>
          <div v-feature-request>
            <Button
              v-if="currentTab === 'Tax'"
              class="text-white"
              :background="appearance.buttonColor || defaultBTNColor"
              @click="handleRemittanceSetup"
            >
              Remittance
            </Button>
          </div>
        </div>
        <div class="mx-3 mt-6">
          <Tab
            :tabs="['Location', 'Tax']"
            border
            :active-tab="currentTab"
            @currentTab="currentTab = $event"
          />
          <location
            v-if="currentTab === 'Location'"
            :location-data="locationData"
            :loading="loading"
            :open-modal="openLocation"
            @close="openLocation = false"
          />
          <tax
            v-if="currentTab === 'Tax'"
            :tax-data="taxData"
            :open-modal="openModal"
            @closeTax="openModal = false"
          />
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Tab from "@scelloo/cloudenly-ui/src/components/tab";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Location from "./Location";
import Tax from "./Tax";

export default {
  name: "LocationAndTax",
  components: {
    Tab,
    Location,
    Button,
    Tax,
    Card
  },
  data() {
    return {
      currentTab: "Location",
      locationData: [],
      taxData: [],
      loading: true,
      openModal: false,
      openLocation: false
    };
  },
  methods: {
    submit() {},
    async handleRemittanceSetup() {
      try {
        await this.$handlePrivilege(
          "tax",
          "setupOnlinePayeRemittance"
        );
        this.openModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    },
    getName(value, array) {
      if (!value) {
        return "";
      }
      const result = array.find(({ id }) => id === value);
      if (result) {
        return result.name;
      }
      return value;
    },
    async getLocations() {
      try {
        const { data } = await this.$_getHumanarLocations();
        this.locationData = data.outlets;
        this.loading = false;
      } catch (e) {
        throw new Error(e);
      }
    },
    async getTax() {
      try {
        const { data } = await this.$_getOrgTaxAuthorityTins();
        this.taxData = data.taxAuthorities.map(item => ({
          id: item.id,
          name: item.name,
          code: item.code,
          state: item.state.name,
          label: "PAYE",
          tin: item.tin ? item.tin.tin : "",
          tinId: item.tin ? item.tin.id : "",
          editable: false
        }));
      } catch (e) {
        throw new Error(e);
      }
    },

    async addLocation() {
      try {
        await this.$handlePrivilege("location", "addNewLocation");
        this.id = "";
        this.openLocation = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    }
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  mounted() {
    this.getLocations();
    this.getTax();
  }
};
</script>

<style scoped>
.custom-radius {
  border-radius: 7px;
}
</style>
