<template>
  <div>
    <div v-if="!errorPage">
      <div>
        <span
          :class="`w-1/5 shadow-lg p-3 -mt-20 text-white
        text-sm text-center absolute ${responseBg}`"
          style="left: 77%"
          v-if="success"
        >
          {{ response }}
        </span>
        <Card class="mt-6 p-5">
          <template v-slot:footer>
            <card-footer
              @sortType="handleSort($event)"
              @searchResult="handleSearch($event)"
            />
          </template>
        </Card>
      </div>
      <div class="pt-3">
        <Table
          :headers="taxHeader"
          :items="taxData"
          style="width:100%; height:auto"
          class="w-full"
          aria-label="tax table"
          :has-checkbox="false"
          has-number
          :loading="loading"
        >
          <template v-slot:item="{ item }">
            <div v-if="item.tin" style="width: 189px">
              <div class="flex justify-between" v-if="item.data.editable">
                <c-text
                  variant="w-36"
                  placeholder="Enter TIN"
                  v-model="item.data.tin"
                />
                <div @click="saveOrgTin(item.data)">
                  <icon
                    icon-name="save"
                    class-name="text-flame ml-3 pt-4 cursor-pointer"
                    size="xs"
                  />
                </div>
              </div>
              <p v-else>
                {{ item.data.tin }}
              </p>
            </div>
            <span v-else-if="item.id">
              <div
                @click="handleEditable(item.data.id)"
                class="cursor-pointer"
                v-if="!item.data.editable"
              >
                <icon
                  icon-name="edit"
                  class-name="text-blueCrayola"
                  size="xs"
                />
              </div>
              <div
                class="cursor-pointer"
                @click="item.data.editable = false"
                v-else
              >
                <icon icon-name="close" size="xs" />
              </div>
            </span>
          </template>
        </Table>
      </div>
      <RightSideBar
        v-if="openModal"
        @submit="remitTax"
        @close="$emit('closeTax')"
        submit="Save"
        :button-class="`bg-dynamicBackBtn text-white`"
        :disabled-button="disableBtn"
      >
        <template v-slot:title>
          <p class="text-darkPurple">Remittance</p>
        </template>
        <template v-slot:subtitle>
          <p class="font-semibold text-darkPurple">Select remittance option</p>
          <p class="text-darkPurple">
            The system automatically calculates individual PAYE per location and
            can help electronically remit to the relevant authorities.
          </p>
          <radio-button
            :options="categoryArray"
            col-span="col-span-12"
            class="m-4 text-darkPurple"
            row-gap="gap-y-5"
            v-model="remittance"
          />
        </template>
      </RightSideBar>
    </div>

    <div v-else>
      <ErrorPage />
    </div>
  </div>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import states from "@/helpers/states";
import countries from "@/helpers/countries";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";
import RadioButton from "@/components/RadioButton";
import ErrorPage from "@/modules/Admin/error403";

export default {
  name: "Tax",
  components: {
    Card,
    Table,
    ErrorPage,
    RightSideBar,
    CText,
    CardFooter,
    RadioButton,
  },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disableBtn: false,
      states,
      countries,
      currentTab: "Location",
      data: [],
      taxData: [],
      searchWord: null,
      sortType: null,
      loading: true,
      errorPage: false,
      remittance: "",
      response: "",
      responseBg: "",
      success: false,
      taxHeader: [
        { title: "TAX AUTHORITY", value: "name", width: 27 },
        { title: "AUTHORITY CODE", value: "code", width: 20 },
        { title: "STATE", value: "state", width: 17 },
        { title: "TAX LABEL", value: "label", width: 11 },
        { title: "TIN", value: "tin", width: 10 },
        { title: "", value: "id", image: true },
      ],
      categoryArray: [
        {
          name: "Yes, set me up for automatic remittance",
          radioName: "tax",
          value: true,
        },
        {
          name: "No, do not set me up for automatic remittance",
          radioName: "tax",
          value: false,
        },
      ],
    };
  },
  methods: {
    async handleEditable(id) {
      try {
        await this.$handlePrivilege("tax", "updateTaxIdentityNumber(tin)");
        const result = this.taxData.find((tax) => tax.id === id);
        if (result) {
          result.editable = true;
        }
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handleSort(sortType) {
      this.loading = true;
      const changeSortType = sortType.split(":");
      let result;
      if (changeSortType[0] === "createdAt") {
        result = sortType;
      } else {
        result = `name:${changeSortType[1]}`;
      }
      this.sortType = result;
      this.queryUpdate();
    },
    queryUpdate() {
      const search = this.searchWord ? `&search=${this.searchWord}` : "";
      const sort = this.sortType ? `&sort=${this.sortType}` : "";
      this.getTax(`${sort}${search}`);
    },
    handleSearch(searchWord) {
      this.loading = true;
      if (searchWord) {
        this.searchWord = searchWord;
        this.queryUpdate();
      } else {
        this.searchWord = null;
        this.queryUpdate();
      }
    },
    saveOrgTin(taxData) {
      // eslint-disable-next-line no-param-reassign
      taxData.editable = false;
      const payload = {
        orgId: this.$orgId,
        taxAuthorityId: taxData.id,
        tin: taxData.tin,
      };
      this.$_addOrgTaxAuthorityTin(payload)
        .then()
        .catch((err) => {
          throw new Error(err);
        });
    },
    remitTax() {
      this.disableBtn = true;
      const payload = {
        orgId: this.$orgId,
        canRemitTaxAutomatically: this.remittance,
      };
      this.$_saveTaxRemittance(payload)
        .then((result) => {
          this.$emit("closeTax");
          this.$toasted.success(`${result.data.message}`, { duration: 3000 });
          this.disableBtn = false;
        })
        .catch((err) => {
          this.$toasted.error("Action not successful", { duration: 3000 });
          this.disableBtn = false;
          throw new Error(err);
        });
    },
    getName(value, array) {
      if (!value) {
        return "";
      }
      const result = array.find(({ id }) => id === value);
      if (result) {
        return result.name;
      }
      return value;
    },
    async getTax(query) {
      try {
        const { data } = await this.$_getOrgTaxAuthorityTins(query);
        this.taxData = data.taxAuthorities.map((item) => ({
          id: item.id,
          name: item.name,
          code: item.code,
          state: item.state.name,
          label: "PAYE",
          tin: item.tin ? item.tin.tin : "",
          tinId: item.tin ? item.tin.id : "",
          editable: false,
        }));
        this.loading = false;
      } catch (e) {
        throw new Error(e);
      }
    },
  },
  async mounted() {
    try {
      await this.$handlePrivilege("tax", "viewRegister");
      this.getTax();
    } catch (error) {
      this.errorPage = true;
    }
  },
};
</script>
